import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="To Do List App Template | Uizard"
    canonical="/templates/mobile-app-templates/to-do-list-mobile-app/"
    category="Tablet Templates"
    url="/templates/tablet-templates/to-do-list-tablet-app/"
    metaDescription="Design the perfect to-do list tablet app with Uizard. With our chic tablet app template, you can bring your app design to life in minutes."
    description="
    h2:Say hello to Organic Mind; a to do list app design template for tablet
    <br/>
    Open your mind to Organic Mind, Uizard's tablet to do list app UI template. Organic Mind comes with everything you would expect from a <a:https://uizard.io/prototyping/>UX/UI prototyping tool</a> design template including a stunning range of mockup screens and all the ideal user flows built right in. 
    <br/>
    h3:Design your to do list tablet app in minutes not weeks
    <br/>
    As with all our tablet UI design templates, Organic Mind is endlessly customizable, meaning you can use the design right out the box, or you can spend as long as you want editing and updating. All our <a:https://uizard.io/templates/>UI templates</a> are built by professional designers as standard, guaranteeing you quality with whatever you are trying to design.
    <br/>
    h3:Bring your vision to life faster than ever before
    <br/>
    Uizard empowers you to design your own digital product, even if you have no design experience. Use our templates as the basis for your own project or start from scratch with our easy-to-use design interface. Tablet app design just got a whole lot easier.
    "
    pages={[
      "A stunning, minimalist web app homepage",
      "A log-in page with clear and simple calls to action",
      "List pages to demonstrate bespoke categorization of tasks by type",
      "A search directory with various filters",
    ]}
    projectCode="5E9byeEg1YSlnaGbe8x1"
    img1={data.image1.childImageSharp}
    img1alt="to do list tablet app design template cover"
    img2={data.image2.childImageSharp}
    img2alt="to do list tablet app design template overview screen"
    img3={data.image3.childImageSharp}
    img3alt="to do list tablet app design template tasks screen"
    img4={data.image4.childImageSharp}
    img4alt="to do list tablet app design template calendar screen"
    img5={data.image5.childImageSharp}
    img5alt="to do list tablet app design template summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: { eq: "templates/to-do-tablet-app/to-do-tab-app-cover.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/to-do-tablet-app/to-do-tab-app-overview.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: { eq: "templates/to-do-tablet-app/to-do-tab-app-tasks.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/to-do-tablet-app/to-do-tab-app-calendar.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/to-do-tablet-app/to-do-tab-app-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
